import React from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import cx from "classnames";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import WorkCard from "../../utils/Works Card/card";
import styles from "./landing.module.scss";
import rdlogo from "../../Images/Icons/rockonlogo_long.png";
import vibeFinderImage from "../../Images/Projects/vibefinder1.png"

const postsCardData = [
  { heading: "Making a design system from scratch", type: "Design, Pattern" },
  {
    heading: "Creating pixel perfect icons in Figma",
    type: "Figma, Icon Design",
  },
];

const featureWorksData = [
  {
    heading: "SoleMarks",
    type: "Web App",
    img:
      "http://once-aday.github.io/images/app_layout_6152021_Selected_2.JPG",
    body: 'Solemarks is a natural-world discovery and collection app comprised of maps that enable you to digitally scout things, locally and around the world, and then add them to a virtual bag when you find them in person. Sole Geology is one of those maps.',
    year:'2022'
    },
  {
    heading: "VibeFinder",
    type: "iOS App",
    img:
    "http://once-aday.github.io/images/vf_mockup2.png",
    body: 'People who have specific sensitivities (sober, vegan, etc.) often have trouble finding experiences and environments to fulfil their needs. VibeFinder is an iOS App that allows for instant access to comfortable, quality environments, providing just the right vibe. Design by Brooks Rocco.',
    year:'2021'
    },
  {
    heading: "Cycle Safe",
    type: "Web Map",
    img:
      "http://once-aday.github.io/images/bikethumb.PNG",
    body: 'Cyclesafe enables a user to plan their bicycle trip by identifying roads with the largest shoulder widths. By choosing a bicycling route with larger shoulders the bicycling trip can potentially become safer, more leisurely and fun.',
    year:'2017'
    },
  {
    heading: "WashedAshore Traveling Exhibit Map",
    type: "Web Map",
    img:
      "http://once-aday.github.io/images/te_thumb.png",
    body: 'This application was designed on top of mapbox-gl with the use of jQuery and information provided by Washed Ashore. This organization is strongly rooted in arts education. I designed the map to have a playful and creative feel to it rather than have the focus be purely on data display.',
    year:'2017'
  }
    ];

export default function Landing() {
  const devImg =
    "http://once-aday.github.io/images/bus_stops_thumb.jpg";
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ when: "beforeChildren", staggerChildren: 0.4, duration: 1 }}
    >
      <section className={cx(styles.section, styles.intro_Section)}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextImg}>
            <div className={styles.devImg_BelowTablet}>
              <img src={devImg} alt='developer' />
            </div>
            <div className={styles.headerText}>
              <h4>
                <img src={rdlogo} alt='developer' />
                {/* <div style={{'fontSize':'small', 'marginTop':'-10'}}>
                  rock-on.dev
                </div> */}
                <h6>
                 Modern software development
                <br /> Geospatial expertise
                </h6>
              </h4>
              <p>
                Projects primarily designed and implimented by me, Devin Rocco.
              </p>
            </div>
            <div className={styles.devImg_UptoTablet}>
              <img src={devImg} alt='developer' />
            </div>
          </div>
          <div>
            <Button
              className={styles.downloadBtn}
              variant='contained'
              color='default'
            >
              Download Resume
            </Button>
          </div>
        </div>
      </section>
      {/* <section className={cx(styles.section, styles.recentPost_Section)}>
        <Grid
          container
          justify='space-between'
          style={{ margin: 0, width: "100%" }}
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant='h6'>Recent posts</Typography>
            <Typography>
              <Link
                style={{ color: "#00A8CC", textDecoration: "none" }}
                to='/blog'
              >
                View all
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              {postsCardData.map((details) => (
                <Grid key={details.type} item xs={12} sm={12} md={6}>
                  <Card className={styles.postsCard} elevation={3}>
                    <span>{details.heading}</span>
                    <span>12 Feb 2020 | {details.type}</span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquam eum reiciendis sint! Officia fuga rem iure commodi
                      dolore maiores nobis!
                    </span>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </section> */}
      <section className={cx(styles.section, styles.featuredWork_Section)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant='h6' color='initial'>
              Featured Projects
            </Typography>
          </Grid>
          {featureWorksData.map((work) => (
            <Grid
              key={work.type}
              item
              xs={12}
              sm={12}
              md={12}
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <WorkCard
                heading={work.heading}
                img={work.img}
                type={work.type}
                body={work.body}
                year={work.year}
              />
            </Grid>
          ))}
        </Grid>
      </section>
    </motion.div>
  );
}
